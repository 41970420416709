<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="pa-3 shadowCard">
      <v-card-title primary-title>
        Mi aguinaldo
        <v-spacer></v-spacer>
        <v-btn 
          color="success"
          tile
          @click="abrirFirma( )"
          v-if="!firma"
        >
          Firmar documento
        </v-btn>

        <v-alert
          dense
          text
          type="success"
          v-else
        >
          Documento firmado
        </v-alert>
      </v-card-title>

      <v-row dense>
        <v-col cols="12">
          <embed 
            :src="`https://escuelakpi.club/viewer/web/viewer.html?file=https://escuelakpi.club/imagenes-aguinaldo/constancia_finiquito.pdf`" 
            width="100%" 
            :height="tamanioPantalla" 
            v-if="!firma"
          />
          <embed 
            :src="`https://escuelakpi.club/viewer/web/viewer.html?file=https://escuelakpi.club/imagenes-aguinaldo/firma-${getdatosUsuario.id_trabajador}.pdf`" 
            width="100%" 
            :height="tamanioPantalla" 
            v-else
          />
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card outlined class="mb-2">
        <v-card-text class="black--text">
          Hola
        </v-card-text>
        <div>
          <canvas id="signature-pad" class="signature-pad" width="780" height="250"></canvas>
        </div>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="savePNG()"        
            small
            class="mr-2"
          >
            Firmar documento
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="limpiar()"
            small
          >
            <span v-if="this.$vuetify.breakpoint.name == 'xs'">
              <v-icon color="primary">mdi-eraser</v-icon>
            </span>
            <span v-else>
              Limpiar
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  import SignaturePad from 'signature_pad'


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      signaturePad:null,

      descriptor: null,

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,

      firma: false,
      aguinaldo: null

    }),

    created( ) {
      this.initialize()
    },

    mounted () {
      // let canvas = document.getElementById('signature-pad');
      // this.signaturePad = new SignaturePad(canvas);
    },

    methods:{

      abrirFirma( ){
        this.dialog = true

        setTimeout(() => {
          let canvas = document.getElementById('signature-pad');
          this.signaturePad = new SignaturePad(canvas);
        }, 2000);

      },

      ver (pdf) {
        this.viewPdf = pdf
        this.dialog = true
        this.verElPdf = 'https://escuelakpi.club/manuales/reglamento.pdf'
      },

      initialize ( ) {
        this.cargar = true

        this.$http.get('aguinaldo.firma/' + this.getdatosUsuario.id_trabajador).then(response=>{
          this.cargar       = false
          this.firma        = response.data.estatusFirma
          this.aguinaldo    = response.data.aguinaldo
        }).catch( error =>{ 
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })  
      },

      async savePNG (){
        if(this.signaturePad.isEmpty()){
          this.validarErrorDirecto('Necesitas ingresar una firma')
        }else{
          this.cargar = true

          let url = this.signaturePad.toDataURL();

          // Archivo blob binario
          let blobBin = atob(url.split(',')[1]);
          let array = [];
          // Recorremos el resultado
          for(let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }

          // Convertimos a bloc
          const file = new Blob([new Uint8Array(array)], {type: 'image/png'});
          // Lo convertimos a archivo para poder agregarle una imagen
          const file2 = new File([file], this.folio+".png",{ type: "image/png" })

          // Creamos el documento
          let formData = new FormData();
          // agregamos el documento
          formData.append("file", file2);
          formData.append("nombre"          , this.getdatosUsuario.nombre_completo);
          formData.append("id_trabajador"   , this.getdatosUsuario.id_trabajador  );
          formData.append("aguinaldo"       , this.format( this.aguinaldo.aguinaldo ) );

          // Guardamos el documento
          this.$http.post('crear-firma-aguinaldo/' + this.getdatosUsuario.id_trabajador, formData).then(response=>{
            this.cargar = false
            this.signaturePad.clear();
            this.dialog = false
            this.validarSuccess('Documento firmado correctamente')
            this.initialize( )
            // window.open(axios.defaults.baseURL + 'contratos/' + this.folio + '.pdf')
          }).catch(error=>{
            this.validarError(error.body.message)
          })
        }
      },

      limpiar (){
        this.signaturePad.clear();
      },

      updateDatos(){
        if(this.nombre_completo == '' || this.numext == '' || this.colonia == '' || this.ciudad == '' || this.cp == '' || this.estado == '' || this.calle == ''){
          this.validarError('Llena todos los datos')
        }else{
          if(this.folio != ''){
            this.cargar = true
            const payload = {
              folio:            this.folio,
              nombre_completo:  this.nombre_completo,
              direccion:        this.calle + ', ' + this.numext + ', ' + this.colonia + ', ' + this.ciudad + ', ' + this.cp + ', ' + this.estado
            }
            this.$http.post('operaciones.contrato.actualiza.datos', payload).then(response=>{
              this.e1 = 3
              this.cargar = false
            }).catch(error=>{
              this.cargar = false
              this.validarError(error.body.message)
            })
          }else{
            this.validarError('Lo sentimos, hubo un error')
          }
        }
      },

      validaFolio(){
        if(this.folio != ''){
          this.cargar = true
          const payload = {
            folio: this.folio
          }
          this.$http.post('operaciones.contrato.valida', payload).then(response=>{
            if(response.data.estatus == 1){
              this.e1 = 2
              this.id = response.data.id
            }else{
              // this.openDialogSuccess(response.data.message)
              this.dialogAfimativo = true
            }
            this.cargar = false
          }).catch(error=>{
            this.cargar = false
            this.validarError(error.body.message)
          })
        }else{

        }
      },

      validarError (mensaje) {
        this.error       = mensaje
        this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
        this.successMensaje = mensaje
        this.dialogSuccess  = true
      },

      format( valor ){
	      return valor.toLocaleString('es-MX', {
	        style: 'currency',
	        currency: 'MXN'
	      });
	    },
    },

    computed:{
      ...mapGetters( 'login' ,['getdatosUsuario']),

      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -185
      },

      width(){
        return this.$vuetify.breakpoint.width
      },

      height(){
        return this.$vuetify.breakpoint.height - 250
      }
    }
  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}
  
</style>